<template>
  <b-card
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <span class="text-chart mb-1">
      Thành phần các loại hình doanh nghiệp
    </span>
    <!-- chart -->
    <vue-apex-charts
      :options="statisticsProfit.chartOptions"
      :series="statisticsProfit.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      statisticsProfit: {
        chartOptions: {
          chart: {
            type: 'donut',
          },
          plotOptions: {
            pie: {
              donut: {
                size: '45%',
              },
            },
          },
          labels: ['DN Nhà nước', 'Công ty TNHH', 'Công ty cổ phần', 'DN Tư nhân', 'DN FDI', 'Hợp tác xã', 'Công ty hợp doanh', 'Khác'],
        },
      },
    }
  },
  mounted() {
    this.fetchListUsers()
  },
  methods: {
    async fetchListUsers() {
      const { data } = await axiosApiInstance.get(ConstantsApi.BUSSINESS_BY_TYPE, {
        params: {},
      })
      const temp = this.getTemp()
      if (data?.length > 0) {
        temp.chartOptions.labels = data.map(x => x.bTypeString)
        temp.series = data.map(x => x.count)
      }
      this.statisticsProfit = temp
    },
    getTemp() {
      return {
        chartOptions: {
          chart: {
            type: 'donut',
          },
          plotOptions: {
            pie: {
              donut: {
                size: '45%',
              },
            },
          },
          labels: ['DN Nhà nước', 'Công ty TNHH', 'Công ty cổ phần', 'DN Tư nhân', 'DN FDI', 'Hợp tác xã', 'Công ty hợp doanh', 'Khác'],
        },
        series: [0, 0, 0, 0, 0, 0, 0],
      }
    },
  },
}
</script>
