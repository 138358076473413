<template>
  <b-card
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <span class="text-chart mb-1">
      Số lượng tai nạn trong 10 năm gần đây
    </span>
    <!-- chart -->
    <vue-apex-charts
      :options="statisticsProfit.chartOptions"
      :series="statisticsProfit.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      statisticsProfit: this.getTemp(),
    }
  },
  mounted() {
    this.fetchListUsers()
  },
  methods: {
    async fetchListUsers() {
      const { data } = await axiosApiInstance.get(ConstantsApi.ACCIDENT_10_YEARS, {
        params: {},
      })
      const temp = this.getTemp()
      if (data?.length > 0) {
        temp.chartOptions.xaxis.categories = data.map(x => x.year)
        temp.series[0].data = data.map(x => x.count)
      }
      this.statisticsProfit = temp
    },
    getTemp() {
      return {
        chartOptions: {
          chart: {
            type: 'line',
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.warning],
          xaxis: {
            categories: [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020],
            show: true,
          },
          yaxis: {
            show: true,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
        series: [
          {
            name: 'Số lượng tai nạn lao động',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      }
    },
  },
}
</script>
