<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col>
        <analytics-statistics />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <analytics-company-chart />
      </b-col>
      <b-col lg="6">
        <analytics-accident-chart />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <analytics-company-percent-chart />
      </b-col>
      <b-col lg="6">
        <analytics-worker-percent-chart />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import AnalyticsStatistics from '../analytics/AnalyticsStatistics.vue'
import AnalyticsCompanyChart from '../analytics/AnalyticsCompanyChart.vue'
import AnalyticsAccidentChart from '../analytics/AnalyticsAccidentChart.vue'
import AnalyticsCompanyPercentChart from '../analytics/AnalyticsCompanyPercentChart.vue'
import AnalyticsWorkerPercentChart from '../analytics/AnalyticsWorkerPercentChart.vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsStatistics,
    AnalyticsCompanyChart,
    AnalyticsAccidentChart,
    AnalyticsCompanyPercentChart,
    AnalyticsWorkerPercentChart,
  },
  data() {
    return {
      data: {
        congratulations: {},
      },
    }
  },
  methods: {

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
