<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Thống kê</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'BriefcaseIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Doanh nghiệp',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'CheckSquareIcon',
          color: 'light-info',
          title: '0',
          subtitle: 'Tổ chức kiểm định',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Tổ chức huấn luyện',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-success',
          title: '0',
          subtitle: 'Báo cáo',
          customClass: '',
        },
      ],
    }
  },
  mounted() {
    this.fetchListUsers()
  },
  methods: {
    async fetchListUsers() {
      const { data } = await axiosApiInstance.get(ConstantsApi.SUMARY, {
        params: {},
      })
      this.statisticsItems[0].title = data?.countBussiness ? data?.countBussiness : 0
      this.statisticsItems[1].title = data?.countTraining ? data?.countTraining : 0
      this.statisticsItems[2].title = data?.countAccreditation ? data?.countAccreditation : 0
      this.statisticsItems[3].title = data?.countReport ? data?.countReport : 0
    },
  },
}
</script>
